import React, { useState } from 'react';
import ImagePopup from './ImagePopup';

function CertifSection() {
  // Define the list of image URLs for the certificates in the div
  const certificateImagesDiv = [
    "/images/certificates/iso-logo-01.webp",
    "/images/certificates/iso-logo-02.webp",
    "/images/certificates/iso-logo-03.webp",
    "/images/certificates/certificate-icon-01.webp",
    "/images/certificates/certificate-icon-02.webp",
    "/images/certificates/certificate-icon-03.webp",
    "/images/certificates/certificate-icon-04.webp",
    "/images/certificates/certificate-icon-05.webp",
    "/images/certificates/certificate-icon-06.webp",
    "/images/certificates/certificate-icon-07.webp",
    "/images/certificates/certificate-icon-08.webp",
    "/images/certificates/certificate-icon-09.webp",


  ];

  // Define the list of image URLs for the certificates in the popup
  const certificateImagesPopup = [
    "/images/certificates/certif-01.jpg",
    "/images/certificates/certif-02.jpg",
    "/images/certificates/certif-03.jpg",
    "/images/certificates/certificate-01.webp",
    "/images/certificates/certificate-02.webp",
    "/images/certificates/certificate-03.webp",
    "/images/certificates/certificate-04.webp",
    "/images/certificates/certificate-05.webp",
    "/images/certificates/certificate-06.webp",
    "/images/certificates/certificate-07.webp",
    "/images/certificates/certificate-08.webp",
    "/images/certificates/certificate-09.webp",
  ];

  // State to keep track of which certificate's popup is open
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  // Function to open the popup for a specific certificate
  const handleOpenPopup = (index) => {
    setSelectedCertificate(index);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setSelectedCertificate(null);
  };

  return (
    <div className='certif'>
      <h2>Certifications & Accreditations</h2>
      <p>We boast the highest level of certifications such as ISO 9001, ISO 14001, and ISO 45001. We are proud to have received multiple certificates of appreciation from our clients. Additionally, we are recognized as certified builders by multiple companies.</p>
      <div className='certif-cards'>
        {certificateImagesDiv.map((imageUrl, index) => (
          <div
            key={index}
            className='certif-card'
            onClick={() => handleOpenPopup(index)}
          >
            <img src={imageUrl} alt={`Certificate ${index + 1}`} />
          </div>
        ))}
      </div>
      {selectedCertificate !== null && (
        <ImagePopup
          imageUrl={certificateImagesPopup[selectedCertificate]}
          onClose={handleClosePopup}
          style={{ // Add the styles for the popup image here
            maxWidth: '100%',
            maxHeight: '100%',
            // borderRadius: '5px',
          }}
        />
      )}
    </div>
  );
}

export default CertifSection;
