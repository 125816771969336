import React from 'react';
import CustomNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import CertifSection from '../components/CertifSection';
import { Link } from 'react-router-dom';
import "../css/main.css"
import "../css/about.css"


function About() {


  return (
    <div>
    
    <CustomNavbar />
    {/* <Header 
      title = "About the company"
      subtext = ""
      imgURL = "/images/banners/banner-about-01.webp"
    /> */}

    <div className="hero-about">

<div className='overlay-about'></div>
{/* <Carousel images={images} /> */}
<img src="/images/banners/banner-about-01.webp" alt=''/>


<div className='about-intro'>
        <h2>About US</h2>
        <p>Elite Signature Group is a leading provider of construction and industrial solutions, comprising of Elite Signature Contracting company and Elite Signature Industrial company. With a strong presence across the Kingdom of Saudi Arabia and regional offices in the United Arab Emirates and Egypt, we specialize in steel manufacturing, supply, and installation, along with a diverse range of construction and engineering services.</p>
       
        <a className='' href="/files/Elite signature company profile - Digital.pdf" target='_blank'>
    <button className='button'>Company Profile</button>
    </a>
   </div> 
<div className='animate esGroup'>
<img className='esGroupLogo' src="/images/homepage/es-contracting.webp" alt=''/>
<img className='esGroupLogo' src="/images/homepage/es-industrial.webp" alt=''/>
{/* <img className='esGroupLogo' src="/images/homepage/es-flooring.webp" alt=''/> */}
</div>
</div>




    <div className='text-left'>
      <div className='text'>
        <h1>INTEGRITY MEETS VISION</h1>
        <p>Our vision is to be a top-tier contractor of choice, offering a comprehensive, one-stop solution for the entire industrial project lifecycle from initial advisory to seamless execution and final handover. We strive to be the preferred partner, recognized for our expertise, quality, performance, and reliability in industrial projects and steel structure solutions.</p>
        </div>
        <div className='image'>
        <img src='/images/about/about-page-01.webp' alt=''></img>
        </div>
    </div>

    <div className='text-right'>
      <div className='text'>
        <h1>A MISSION TO ACHIEVE</h1>
        <p>Our mission is to deliver innovative, value-added industrial projects with superior quality and timely execution, ensuring complete customer satisfaction. We are committed to providing exceptional craftsmanship and the highest level of service in the construction and steel structure sectors, setting new standards of excellence in every project we undertake.</p>
        </div>
        <div className='image'>
        <img src='/images/about/about-page-02.webp' alt=''></img>
        </div>
    </div>

    <div className='text-left'>
      <div className='text'>
        <h1>our strategy</h1>
        <p>Our strategy is to distinguish ourselves from the competition by delivering expert, industry-leading services in every market we serve. Our deep understanding of large organizations and strong relationships with key industrial market players provide us with a unique strategic advantage.

By prioritizing exceptional quality, cutting-edge technology, and outstanding customer satisfaction, our approach ensures that both our marketing initiatives and service offerings align seamlessly with our commitment to excellence and the needs of our valued clients.</p>
        </div>
        <div className='image'>
        <img src='/images/about/about-page-03.webp' alt=''></img>
        </div>
    </div>

    {/* <div className='text-right'>
      <div className='text'>
        <h1>CERTIFIED FOR EXCELLENCE</h1>
        <p>
At Elite Signature, we boast the highest level of certifications, demonstrating our unwavering commitment to quality. With prestigious accreditations such as ISO 9001, ISO 14001, and ISO 45001, we consistently uphold stringent standards in every aspect of our work. Additionally, as certified builders recognized by AIC Steel, we have proven our expertise and reliability in the industry.</p>
        </div>
        <div className='image'>
        <img src='/images/about/about-page-04.webp' alt=''></img>
        </div>
    </div> */}
    <CertifSection />
    <Footer />
    </div>

    
  );
}

export default About;

