import React from "react";
import { Link } from "react-router-dom";
import CustomNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import Header from '../components/Header';
// import "../css/main.css";
import "../css/404.css";

const NotFound = () => {
  return (
    <div className="notFoundPage">
      {/* Navbar and Header at the top */}
      <div className="topSection">
        <CustomNavbar />
        <Header 
          title="Elite Signature Contracting"
          subtext=""
          imgURL="/images/banners/banner-about-01.webp"
        />
      </div>

      {/* 404 Content fills the remaining space */}
      <div className="content404">
        <h1>404 - Page Not Found</h1>
        <p>Oops! The page you're looking for doesn't exist. Please click the button below to go home.</p>
        <Link to="/" className="button">Go Home</Link>
      </div>

      {/* Footer always at the bottom */}
      <Footer />
    </div>
  );
};

export default NotFound;
