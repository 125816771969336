import React from 'react';
import { useParams } from 'react-router-dom';
import { kebabCase } from 'lodash';
import projects from '../data/projectData';
import CustomNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ProjectCard from '../components/projectCard';
import Carousel from '../components/carousel';
import NotFound from './NotFound';
import "../css/main.css"
import "../css/projectPage.css"


function ProjectDetailPage() {
  const { projectTitle } = useParams();
  const project = projects.find((p) => kebabCase(p.title) === projectTitle);

  if (!project) {
    return <NotFound />;
  }

  // Get the next three projects based on their IDs
  let nextProjects = projects.filter((p) => p.id > project.id);

  // If there are fewer than 3 projects, loop back to the beginning
  if (nextProjects.length < 3) {
    nextProjects = [
      ...nextProjects,
      ...projects.slice(0, 3 - nextProjects.length)
    ];
  } else {
    nextProjects = nextProjects.slice(0, 3);
  }
  const images = project.images.slideshow;
  const items = project.details.items;
  const values = project.details.values;
  const details = items.map((item, index) => ({
    item,
    value: values[index]
  }));


  return (
    <div>
      <CustomNavbar />
      <Header 
      title = {project.title}
      imgURL = "/images/banners/banner-project-02.webp"
    />
      
<div className='big-box'>
      <div className='project-box'>
      <div className='project-image'>
      <Carousel images={images} />
      </div>
      <div className='project-details'> 
  {details.map((detail, index) => (
    <div key={index}>
      <h3>{detail.item}</h3>
      <p>{detail.value}</p>
    </div>
  ))}
</div>

      </div>
        <p>{project.description}</p>
      </div>
      

      
      {/* Render other project details */}

<div className='projects-section'>
      <h2>More Projects</h2>
      <p></p>
      <div className='project-cards'>
      {nextProjects.map((nextProject) => (
        <ProjectCard 
                id={nextProject.id}
                title={nextProject.title}
                imgURL={nextProject.images.mainImage}
              />
      ))}
      </div>
</div>


      <Footer />
    </div>
  );
}

export default ProjectDetailPage;
