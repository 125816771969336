import React, { useState } from 'react';

function ClientSection2() {
  

  return (

    <div className='clients-section'>
    <div class="slider">
  <div class="slide-track">

  

      <div class="slide">
          <img src="/images/logo/logo-26.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-27.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-28.webp" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-29.webp" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-30.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-31.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-32.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-33.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-34.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-35.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-36.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-37.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-38.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-39.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-40.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-41.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-42.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-43.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-44.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-45.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-46.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-47.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-48.webp" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-49.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-50.png" height="100" width='250'  alt="" />
      </div>

      

  <div class="slide">
          <img src="/images/logo/logo-26.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-27.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-28.webp" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-29.webp" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-30.png" height="100" width='250'  alt="" />
      </div>


  
  </div>
</div>
</div>
    
  );
}

export default ClientSection2;
