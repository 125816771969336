const projects = [
     {
        id: 1,
        featured: 4,
        title: "Custodian of the Two Holy Mosque-Sector KAP2E2 – 2 Theatre Building - Madinah",
        description: "Project included 2 buildings. Each building is a theatre and total weight for each building around 600 Ton steel including mezzanine deck panels, shear stud, cut walk and handrails. Theatres have been suspended on concrete buildings on second roof ",
        category: "Category 1",
        service: "steel erection",
        images:
                {
                    mainImage:"/images/projects/madina-project/madina-project-01.jpg",
                    slideshow:["/images/projects/madina-project/madina-project-01.jpg",
                                "/images/projects/madina-project/madina-project-02.jpg",
                                "/images/projects/madina-project/madina-project-03.jpg",
                                "/images/projects/madina-project/madina-project-04.jpg",
                            ]
                },
        details: 
                {
                    items:["client & steel manufacturer ",
                            "consultant",
                            "contractor", 
                            "end user",
                            "location", 
                            "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                            "NKY Architects & Engineers",
                            "BEC contracting ", 
                            "Ministry of Interior Affairs", 
                            "Madinah - KSA", 
                            "Pre-Engineering & Hot Rolled Steel Theatre buildings"
                            ]
                },
    },{
        id: 2,
        featured: 4,
        title: "Rawabi Warehouse Oil & Gas - Dammam",
        description: "Project included 2 buildings. First building workshop around 3000MSQ. Second building warehouse around 27000MSQ all covered by sandwich panels 50mm for roof & wall.",
        category: "Category 1",
        service: "steel erection",
        images:
                {
                    mainImage:"/images/projects/rawabi-project/rawabi-project-01.jpg",
                    slideshow:["/images/projects/rawabi-project/rawabi-project-01.jpg",
                                "/images/projects/rawabi-project/rawabi-project-02.jpg",
                                "/images/projects/rawabi-project/rawabi-project-03.jpg",
                                "/images/projects/rawabi-project/rawabi-project-04.jpg",
                                "/images/projects/rawabi-project/rawabi-project-05.jpg",
                                "/images/projects/rawabi-project/rawabi-project-06.jpg",
                                "/images/projects/rawabi-project/rawabi-project-07.jpg",
                            ]
                },
        details: 
                {
                    items:["client & steel manufacturer ",
                                "consultant",
                                "contractor", 
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                                "Basourah",
                                "Specialized contracting Company",
                                "Rawabi Oil & Gas", 
                                "Dammam - 3rd industrial city ", 
                                "Pre-Engineering Steel Building", 
                            ]
                },
    },{
        id: 3,
        featured: 4,
        title: "Construction Of Steel Workshops - Asfan",
        description: "Project included 2 buildings and total built-up area around 9,000MSQ included steel PEB and single sheet with insulation and usage of buildings for workshops works.",
        category: "Category 2",
        service: "steel erection",
        images:
                {
                    mainImage:"/images/projects/asfan-project/asfan-project-01.jpg",
                    slideshow:["/images/projects/asfan-project/asfan-project-01.jpg",
                                "/images/projects/asfan-project/asfan-project-02.jpg",
                                "/images/projects/asfan-project/asfan-project-03.jpg",
                                "/images/projects/asfan-project/asfan-project-04.jpg",
                                "/images/projects/asfan-project/asfan-project-05.jpg",
                                "/images/projects/asfan-project/asfan-project-06.jpg",
                                "/images/projects/asfan-project/asfan-project-07.jpg",
                            ]
                },
        details: 
                {
                    items:["client & steel manufacturer ",
                                "end user",
                                "location", 
                                "type of work"             
                            ],
                    values:["AIC (Arabian International Company)",
                            "Sabqon International Holding Company",
                            "Asfan – Jeddah - KSA", 
                            "Erection of Pre-Engineering Steel Buildings",
                            ]
                },
    },{
        id: 4,
        featured: 4,
        title: "King Fisal Air Academy 6 sheds for Royal Aircraft - Riyadh",
        description: "Project included 6 buildings and each shed was 9000m2 with a total entire area 54,000m2 including sandwich panels 70mm for roof only.",
        category: "Category 3",
        service: "steel erection",
        images:
                {
                    mainImage:"/images/projects/king-fisal-air-academy-project/king-fisal-air-academy-project-01.jpg",
                    slideshow:["/images/projects/king-fisal-air-academy-project/king-fisal-air-academy-project-01.jpg",
                                "/images/projects/king-fisal-air-academy-project/king-fisal-air-academy-project-02.jpg",
                                "/images/projects/king-fisal-air-academy-project/king-fisal-air-academy-project-03.jpg",
                                "/images/projects/king-fisal-air-academy-project/king-fisal-air-academy-project-04.jpg",
                                "/images/projects/king-fisal-air-academy-project/king-fisal-air-academy-project-05.jpg",
                                "/images/projects/king-fisal-air-academy-project/king-fisal-air-academy-project-06.jpg",
                                "/images/projects/king-fisal-air-academy-project/king-fisal-air-academy-project-07.jpg",
                            ]
                },
        details: 
                {
                    items:["client & steel manufacturer ",
                                "main contractor",
                                "consultant",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                                "JV NAS",
                                "Saudi Consult & Burns and McDonnel",
                                "Ministry of Defence – Royal Air Force", 
                                "Majma’a – Riyadh - KSA", 
                                "Erection of Pre-Engineering Steel Sheds", 
                            ]
                },
    },{
        id: 5,
        featured: 4,
        title: "Mahayel Warehouse & Workshop Project - Asser",
        description: "Project included 120 buildings and total built-up area for the whole buildings around 166,000m2 included steel PEB erection, single sheet with insulation 50mm for roof and wall.",
        category: "Category 1",
        service: "steel erection",
        images:
                {
                    mainImage:"/images/projects/mahayel-project/mahayel-project-01.jpg",
                    slideshow:["/images/projects/mahayel-project/mahayel-project-01.jpg",
                                "/images/projects/mahayel-project/mahayel-project-02.jpg",
                                "/images/projects/mahayel-project/mahayel-project-03.jpg",
                                "/images/projects/mahayel-project/mahayel-project-04.jpg",
                                "/images/projects/mahayel-project/mahayel-project-05.jpg",
                                "/images/projects/mahayel-project/mahayel-project-06.jpg",
                            ]
                },
        details: 
                {
                    items:["client & steel manufacturer ",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                                "Mazayah Company",
                                "Mahayel – Asser - KSA", 
                                "Erection of Pre-Engineering Steel Buildings", 
                            ]
                },
    },{
        id: 6,
        featured: 4,
        title: "Jax A & B - Riyadh",
        description: "Project included 2 buildings each buildings total area 6000m2 rested on concrete building on second level and buildings description multi span including sandwich panels 50mm for roof and wall.",
        category: "Category 3",
        service: "steel erection",
        images:
                {
                    mainImage:"/images/projects/jax-ab-project/jax-ab-project-01.jpg",
                    slideshow:["/images/projects/jax-ab-project/jax-ab-project-01.jpg",
                    "/images/projects/jax-ab-project/jax-ab-project-02.jpg",
                    "/images/projects/jax-ab-project/jax-ab-project-03.jpg",
                    "/images/projects/jax-ab-project/jax-ab-project-04.jpg",
                    "/images/projects/jax-ab-project/jax-ab-project-05.jpg",
                    "/images/projects/jax-ab-project/jax-ab-project-06.jpg",
                    "/images/projects/jax-ab-project/jax-ab-project-07.jpg",
                    "/images/projects/jax-ab-project/jax-ab-project-08.jpg",
                    "/images/projects/jax-ab-project/jax-ab-project-09.jpg",
                    "/images/projects/jax-ab-project/jax-ab-project-10.jpg",
                            ]
                },
        details: 
                {
                    items:["client & steel manufacturer ",
                                "main contractor",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                                "MAC Company",
                                "Ministry of Culture",
                                "Riyadh - KSA", 
                                "Pre-Engineering Steel Buildings", 
                            ]
                },
    },{
        id: 7,
        featured: 3,
        title: "D&B Sound Stage & Production Building 1 & 2 - NEOM",
        description: "Project included 4 buildings and total area was 7,000 m2 including sandwich panels 100mm for roof and wall. There were 2 buildings truss with 40m width and 28m height from the top point. AJC used 6 cranes for lifting the truss 4 cranes with 50ton and 2 cranes with 100 ton and 6 manlift for connection purposes in the same time as per Method statement and lifting plan.",
        category: "Category 2",
        service: "steel erection",
        images:
                {
                    mainImage:"/images/projects/neom-project/neom-project-01.jpg",
                    slideshow:["/images/projects/neom-project/neom-project-01.jpg",
                                "/images/projects/neom-project/neom-project-02.jpg",
                                "/images/projects/neom-project/neom-project-03.jpg",
                                "/images/projects/neom-project/neom-project-04.jpg",
                                "/images/projects/neom-project/neom-project-05.jpg",
                                "/images/projects/neom-project/neom-project-06.jpg",
                                "/images/projects/neom-project/neom-project-07.jpg",
                                "/images/projects/neom-project/neom-project-08.jpg",
                                "/images/projects/neom-project/neom-project-09.jpg",
                                "/images/projects/neom-project/neom-project-10.jpg",
                                "/images/projects/neom-project/neom-project-11.jpg",
                                "/images/projects/neom-project/neom-project-12.jpg",
                            ]
                },
        details: 
                {
                    items:["client & steel manufacturer ",
                                "main contractor",
                                "consultant",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                                "FMC",
                                "AECOM",
                                "NEOM",
                                "NEOM - KSA", 
                                "Erection of Pre-Engineering 2 Steel Buildings & 2 Production Buildings", 
                            ]
                },
    },{
        id: 8,
        featured: 4,
        title: "International Innovation Industries - Riyadh",
        description: "Project included 1 buildings and total built-up area around 2,500m2 included steel PEB & Crane beams and sandwich panels 50mm for roof and wall.",
        category: "Category 4",
        service: "steel erection",
        images:
                {
                    mainImage:"/images/projects/iman-project/iman-project-01.jpg",
                    slideshow:["/images/projects/iman-project/iman-project-01.jpg",
                                "/images/projects/iman-project/iman-project-02.jpg",
                                "/images/projects/iman-project/iman-project-03.jpg",
                                "/images/projects/iman-project/iman-project-04.jpg",
                                "/images/projects/iman-project/iman-project-05.jpg",
                                "/images/projects/iman-project/iman-project-06.jpg",
                                "/images/projects/iman-project/iman-project-07.jpg",
                            ]
                },
        details: 
                {
                    items:["client & steel manufacturer ",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                                "Iman Company for Agencies, Trade and Contracting ",
                                "Riyadh - KSA", 
                                "Erection of Pre-Engineering Steel Building", 
                            ]
                },
    },{
        id: 9,
        featured: 4,
        title: "LW04 Air Traffic Control (ATC) Tower & Airfield Operations - Riyadh",
        description: "Installation of 230 ton of steel PEB on a level height of 60m above the ATC Tower using the tower crane for lifting and installation and a hight experienced workers.",
        category: "Category 4",
        service: "steel erection",
        images:
                {
                    mainImage:"/images/projects/atc-tower-project/atc-tower-project-04.jpg",
                    slideshow:["/images/projects/atc-tower-project/atc-tower-project-01.jpg",
                                "/images/projects/atc-tower-project/atc-tower-project-02.jpg",
                                "/images/projects/atc-tower-project/atc-tower-project-03.jpg",
                                "/images/projects/atc-tower-project/atc-tower-project-04.jpg",
                                "/images/projects/atc-tower-project/atc-tower-project-05.jpg",
                                "/images/projects/atc-tower-project/atc-tower-project-06.jpg",
                                "/images/projects/atc-tower-project/atc-tower-project-07.jpg",
                                "/images/projects/atc-tower-project/atc-tower-project-08.jpg",
                            ]
                },
        details: 
                {
                    items:["client & steel manufacturer ",
                                "main contractor",
                                "consultant",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                                "JV NAS",
                                "Saudi Consult & Burns and McDonnel",
                                "Ministry of Defence - Royal Air Force",
                                "Riyadh - KSA", 
                                "Steel PEB Erection", 
                            ]
                },
    },{
        id: 10,
        featured: 4,
        title: "Tabouk Workshop Project - Tabouk",
        description: "Project included 20 buildings and total built-up area for the whole buildings around 57,000m2 included steel PEB erection, single sheet with insulation 50mm for roof and wall.",
        category: "Category 4",
        service: "steel erection",
        images:
                {
                    mainImage:"/images/projects/tabouk-project/tabouk-project-01.jpg",
                    slideshow:["/images/projects/tabouk-project/tabouk-project-01.jpg",
                                "/images/projects/tabouk-project/tabouk-project-02.jpg",
                                "/images/projects/tabouk-project/tabouk-project-03.jpg",
                                "/images/projects/tabouk-project/tabouk-project-04.jpg",
                                "/images/projects/tabouk-project/tabouk-project-05.jpg",
                                "/images/projects/tabouk-project/tabouk-project-06.jpg",
                                "/images/projects/tabouk-project/tabouk-project-07.jpg",
                            ]
                },
        details: 
                {
                    items:["client & steel manufacturer ",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                                "Mazayah Company",
                                "Tabouk - KSA", 
                                "Erection of Pre-Engineering Steel Buildings", 
                            ]
                },
    },{
        id: 11,
        featured: 4,
        title: "King Fisal Air Academy - Riyadh",
        description: "Project included 5 buildings and total area was 18,911m2 including sandwich panels 70mm for roof and wall.",
        category: "Category 1",
        service: "steel erection",
        images:
                {
                    mainImage:"/images/projects/kfa2-project/kfa2-project-01.jpg",
                    slideshow:["/images/projects/kfa2-project/kfa2-project-01.jpg",
                                "/images/projects/kfa2-project/kfa2-project-02.jpg",
                                "/images/projects/kfa2-project/kfa2-project-03.jpg",
                                "/images/projects/kfa2-project/kfa2-project-04.jpg",
                                "/images/projects/kfa2-project/kfa2-project-05.jpg",
                                "/images/projects/kfa2-project/kfa2-project-06.jpg",
                                "/images/projects/kfa2-project/kfa2-project-07.jpg",
                                "/images/projects/kfa2-project/kfa2-project-08.jpg",
                ]
                },
        details: 
                {
                        items:["client & steel manufacturer ",
                                "main contractor",
                                "consultant",
                                "end user",
                                "location", 
                                "type of work"           
                    ],
                         values:["AIC (Arabian International Company)",
                                "BSS",
                                "Saudi Consult & Burns and McDonnel",
                                "Ministry of Defence - Royal Air Force",
                                "Riyadh - KSA", 
                                "Erection of Pre-Engineering Steel Buildings", 
                    ]
                },
    },{
        id: 12,
        featured: 2,
        title: "King Salman Air Base (Maintenance Hangers) - Riyadh",
        description: "Project included 3 buildings and total area was 12,200 m2 including sandwich panels 70mm for roof and wall. There were 2 buildings truss with 80m width and 28m height from the top point. AJC used 10 cranes for lifting the truss 8 cranes with 50ton and 2 cranes with 100 ton and 10 manlift for connection purposes in the same time as per Method statement and lifting plan.",
        category: "Category 4",
        service: "steel erection",
        images:
                {
                    mainImage:"/images/projects/king-salman-air-base-project/king-salman-air-base-project-01.jpg",
                    slideshow:["/images/projects/king-salman-air-base-project/king-salman-air-base-project-01.jpg",
                                "/images/projects/king-salman-air-base-project/king-salman-air-base-project-02.jpg",
                                "/images/projects/king-salman-air-base-project/king-salman-air-base-project-03.jpg",
                                ]
                },
        details: 
                {
                        items:["client & steel manufacturer ",
                                "main contractor",
                                "consultant",
                                "end user",
                                "supervision",
                                "location", 
                                "type of work"           
                    ],
                         values:["AIC (Arabian International Company)",
                                "JV NAS",
                                "Dar Al Handasah",
                                "Ministry of Defence",
                                "Engineering & Works directorate",
                                "Dirab - Riyadh - KSA", 
                                "Erection of Pre-Engineering Steel Buildings", 
                    ]
                },
    },{
        id: 13,
        featured: 4,
        title: "Warehouse Al Bin Ali - Jeddah",
        description: "Project included 1 buildings and total built-up area around 3,500MSQ included steel PEB and sandwich panels 50mm for roof and wall.",
        category: "Category 4",
        service: "supple & apply",
        images:
                {
                    mainImage:"/images/projects/dammam-sa-project/dammam-sa-project-01.jpg",
                    slideshow:["/images/projects/dammam-sa-project/dammam-sa-project-01.jpg",
                                "/images/projects/dammam-sa-project/dammam-sa-project-02.jpg",
                                "/images/projects/dammam-sa-project/dammam-sa-project-03.jpg",
                                "/images/projects/dammam-sa-project/dammam-sa-project-04.jpg",
                ]
                },
        details: 
                {
                        items:["client & steel manufacturer ",
                                "contractor",
                                "end user",
                                "location", 
                                "type of work"           
                    ],
                         values:["AIC (Arabian International Company)",
                                "Building IDEA trading and contracting Company",
                                "Bin Ali",
                                "Supply and erection of Pre-Engineering Steel Building",
                                "Asfan – Jeddah - KSA", 
                                "Erection of Pre-Engineering Steel Buildings", 
                    ]
                },
    },{
        id: 14,
        featured: 4,
        title: "Quantum Switch – Data Center - Dammam",
        description: "Erection of internal sandwich panels (Cold Rooms) thickness 150mm Rockwool for a total area of 5,500m2. Erection of External sandwich panels thickness 100mm PIR for a total area of 3,500m2.",
        category: "Category 1",
        service: "Panels & Cold Room Panels Erection",
        images:
                {
                    mainImage:"/images/projects/amana-spark-project/amana-spark-project-01.jpg",
                    slideshow:["/images/projects/amana-spark-project/amana-spark-project-01.jpg",
                                "/images/projects/amana-spark-project/amana-spark-project-02.jpg",
                                "/images/projects/amana-spark-project/amana-spark-project-03.jpg",
                                "/images/projects/amana-spark-project/amana-spark-project-04.jpg",
                                "/images/projects/amana-spark-project/amana-spark-project-05.jpg",
                                "/images/projects/amana-spark-project/amana-spark-project-06.jpg",
                ]
                },
        details: 
                {
                        items:["client & main contractor ",
                                "developer",
                                "end user",
                                "consultant",
                                "cladding supplier",
                                "location", 
                                "type of work"           
                    ],
                         values:["Amana Contracting",
                                "SPARK",
                                "Bin Ali",
                                "Tamasuk",
                                "bhpDesign & Gulf Consult",
                                "Roof Metal",
                                "Dammam - KSA", 
                                "Sandwich panels erection (Internal & External)", 
                    ]
                },
    },{
        id: 15,
        featured: 4,
        title: "Nupco Warehouse - Qassim",
        description: "Erection of External sandwich panels for wall thickness 150mm PIR for a total area of 21,000m2 with additional rectification works for roof valley gutters of a total length 700Lm.",
        category: "Category 5",
        service: "Panels & Cold Room Panels Erection",
        images:
                {
                    mainImage:"/images/projects/nupco-qassim-project/nupco-qassim-project-01.jpg",
                    slideshow:["/images/projects/nupco-qassim-project/nupco-qassim-project-01.jpg",
                                "/images/projects/nupco-qassim-project/nupco-qassim-project-02.jpg",
                                "/images/projects/nupco-qassim-project/nupco-qassim-project-03.jpg",
                                "/images/projects/nupco-qassim-project/nupco-qassim-project-04.jpg",
                                "/images/projects/nupco-qassim-project/nupco-qassim-project-05.jpg",
                ]
                },
        details: 
                {
                        items:[
                                "end user",
                                "project manager",
                                "contractor & client",
                                "project program management",
                                "Architecture & Engineering Consultant",
                                "consultant",
                                "cladding supplier",
                                "location", 
                                "type of work"           
                    ],
                         values:["Nupco",
                                "JASARA",
                                "Creet International Contracting Company",
                                "Miebach",
                                "V3 Middle East ",
                                "GAP",
                                "Emirates Industrial Panel EIP",
                                "Qassim - KSA", 
                                "Erection of External Sandwich panels for roof", 
                    ]
                },
    },{
        id: 16,
        featured: 4,
        title: "LW04 Air Traffic Control (ATC) Tower & Airfield Operations - Anchor Bolts - Riyadh",
        description: "Installation of Anchor bolts on the top of ATC Tower around 60m height around 115 Pieces.",
        category: "Category 1",
        service: "Anchor Bolts Installation",
        images:
                {
                    mainImage:"/images/projects/atc-tower-anchor-bolts-project/atc-tower-anchor-bolts-project-01.jpg",
                    slideshow:["/images/projects/atc-tower-anchor-bolts-project/atc-tower-anchor-bolts-project-01.jpg",
                                "/images/projects/atc-tower-anchor-bolts-project/atc-tower-anchor-bolts-project-02.jpg",
                                "/images/projects/atc-tower-anchor-bolts-project/atc-tower-anchor-bolts-project-03.jpg",
                                "/images/projects/atc-tower-anchor-bolts-project/atc-tower-anchor-bolts-project-04.jpg",
                                "/images/projects/atc-tower-anchor-bolts-project/atc-tower-anchor-bolts-project-05.jpg",
                                ]
                },
        details: 
                {
                        items:["client & steel manufacturer ",
                                "main contractor",
                                "consultant",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                                "JV NAS",
                                "Saudi Consult & Burns and McDonnel",
                                "Ministry of Defence - Royal Air Force",
                                "Riyadh - KSA", 
                                "Anchor Bolts Installation", 
                            ]
                },
    },{
        id: 17,
        featured: 4,
        title: "King Salman Air Base (Maintenance Hangers) - Anchor Bolts - Riyadh",
        description: "Project included 3 buildings and total area was 12,200 m2. We installing the anchor bolts for fixed base plat with total anchors in one base plate was 24 anchor.",
        category: "Category 1",
        service: "Anchor Bolts Installation",
        images:
                {
                    mainImage:"/images/projects/king-salman-air-base-anchor-bolts-project/king-salman-air-base-anchor-bolts-project-01.jpg",
                    slideshow:["/images/projects/king-salman-air-base-anchor-bolts-project/king-salman-air-base-anchor-bolts-project-01.jpg",
                    "/images/projects/king-salman-air-base-anchor-bolts-project/king-salman-air-base-anchor-bolts-project-02.jpg",
                    "/images/projects/king-salman-air-base-anchor-bolts-project/king-salman-air-base-anchor-bolts-project-03.jpg",
                    "/images/projects/king-salman-air-base-anchor-bolts-project/king-salman-air-base-anchor-bolts-project-04.jpg",
                    "/images/projects/king-salman-air-base-anchor-bolts-project/king-salman-air-base-anchor-bolts-project-05.jpg",
                    "/images/projects/king-salman-air-base-anchor-bolts-project/king-salman-air-base-anchor-bolts-project-06.jpg",
                    "/images/projects/king-salman-air-base-anchor-bolts-project/king-salman-air-base-anchor-bolts-project-07.jpg",
                    "/images/projects/king-salman-air-base-anchor-bolts-project/king-salman-air-base-anchor-bolts-project-08.jpg",
                                ]
                },
        details: 
                {
                        items:["client & steel manufacturer ",
                                "main contractor",
                                "consultant",
                                "end user",
                                "supervision",
                                "location", 
                                "type of work"           
                    ],
                         values:["AIC (Arabian International Company)",
                                "JV NAS",
                                "Dar Al Handasah",
                                "Ministry of Defence",
                                "Engineering & Works directorate",
                                "Dirab - Riyadh - KSA", 
                                "Erection of Pre-Engineering Steel Buildings", 
                    ]
                },
    },{
        id: 18,
        featured: 4,
        title: "Riyadh Nursery Project - Riyadh",
        description: "Project included 1 building. Our scope of work to apply fire paint Intumescent 700 micron- one hour for main items (Columns & Rafters). Total area of the project was 12,000m2 and we executed the work in 3 weeks.",
        category: "Category 1",
        service: "Fire Paint System",
        images:
                {
                    mainImage:"/images/projects/nursery-project/nursery-project-01.jpg",
                    slideshow:["/images/projects/nursery-project/nursery-project-01.jpg",
                                "/images/projects/nursery-project/nursery-project-02.jpg",
                                "/images/projects/nursery-project/nursery-project-03.jpg",
                                "/images/projects/nursery-project/nursery-project-04.jpg",
                                "/images/projects/nursery-project/nursery-project-05.jpg",
                                "/images/projects/nursery-project/nursery-project-06.jpg",
                                ]
                },
        details: 
                {
                        items:["client & steel manufacturer",
                                "main contractor",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                                "Al Majal Al Arabi Group Company",
                                "Nursery Company",
                                "Riyadh - KSA", 
                                "Applying Fire system paint (Intumescent 1 hour 700 micron)", 
                            ]
                },
    },{
        id: 19,
        featured: 4,
        title: "LW04 Air Traffic Control (ATC) Tower & Airfield Operations - Fire Paint System - Riyadh",
        description: "Our scope of work to apply fire paint Intumescent 2200 micron on the ground before lifting the steel for 2 and half hour for the steel items (Columns – Ring Beams – Joists – Main beam -Steel Stairs). Total area tonnage of the project was 220 ton of steel PEB.",
        category: "Category 1",
        service: "Fire Paint System",
        images:
                {
                    mainImage:"/images/projects/atc-tower-fire-paint-project/atc-tower-fire-paint-project-01.jpg",
                    slideshow:["/images/projects/atc-tower-fire-paint-project/atc-tower-fire-paint-project-01.jpg",
                                "/images/projects/atc-tower-fire-paint-project/atc-tower-fire-paint-project-02.jpg",
                                "/images/projects/atc-tower-fire-paint-project/atc-tower-fire-paint-project-03.jpg",
                                "/images/projects/atc-tower-fire-paint-project/atc-tower-fire-paint-project-04.jpg",
                                "/images/projects/atc-tower-fire-paint-project/atc-tower-fire-paint-project-05.jpg",
                                "/images/projects/atc-tower-fire-paint-project/atc-tower-fire-paint-project-06.jpg",
                                "/images/projects/atc-tower-fire-paint-project/atc-tower-fire-paint-project-07.jpg",
                                ]
                },
        details: 
                {
                        items:["client",
                                "main contractor",
                                "consultant",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                                "JV NAS",
                                "Saudi Consult & Burns and McDonnel",
                                "Ministry of Defence - Royal Air Force",
                                "Riyadh - KSA", 
                                "Applying intumescent paint system for 2.5 hour with 2200 micron.", 
                            ]
                },
    },{
        id: 20,
        featured: 4,
        title: "Custodian of the Two Holy Mosque-Sector KAP2E2 – 2 Theatre Building - Shear Studs Work - Madinah",
        description: "Our scope of work to install 20,000 pieces on deck panels using a high-performance shear stud machines for 2 steel structure buildings.",
        category: "Category 1",
        service: "Shear Studs, Modification, Fabrication",
        images:
                {
                    mainImage:"/images/projects/bec-madinah-project/bec-madinah-project-01.jpg",
                    slideshow:["/images/projects/bec-madinah-project/bec-madinah-project-01.jpg",
                                "/images/projects/bec-madinah-project/bec-madinah-project-02.jpg",
                                ]
                },
        details: 
                {
                        items:["client & steel manufacturer",
                                "end user",
                                "contractor",
                                "consultant",
                                "location", 
                                "type of work"           
                            ],
                    values:["AIC (Arabian International Company)",
                                "Ministry of Interior Affairs",
                                "BEC Contracting",
                                "NKY Architects & Engineers",
                                "Madinah - KSA", 
                                "Installation of shear studs works.", 
                            ]
                },
    },{
        id: 21,
        featured: 4,
        title: "Al Wataniya Warehouse modification works - Tabuk",
        description: "Modification works and welding for welding a web & flange paltes for the existing beams and columns since the building was not designed to carry the loads of chiller unites.",
        category: "Category 1",
        service: "Shear Studs, Modification, Fabrication",
        images:
                {
                    mainImage:"/images/projects/tabuk-wataniya-project/tabuk-wataniya-project-01.jpg",
                    slideshow:["/images/projects/tabuk-wataniya-project/tabuk-wataniya-project-01.jpg",
                                "/images/projects/tabuk-wataniya-project/tabuk-wataniya-project-02.jpg",
                                "/images/projects/tabuk-wataniya-project/tabuk-wataniya-project-03.jpg",
                                "/images/projects/tabuk-wataniya-project/tabuk-wataniya-project-04.jpg",
                                "/images/projects/tabuk-wataniya-project/tabuk-wataniya-project-05.jpg",
                                "/images/projects/tabuk-wataniya-project/tabuk-wataniya-project-06.jpg",
                                "/images/projects/tabuk-wataniya-project/tabuk-wataniya-project-07.jpg",
                                "/images/projects/tabuk-wataniya-project/tabuk-wataniya-project-08.jpg",
                                "/images/projects/tabuk-wataniya-project/tabuk-wataniya-project-09.jpg",
                                ]
                },
        details: 
                {
                        items:["client",
                                "location", 
                                "type of work"           
                            ],
                    values:["Al Wataniya",
                                "Tabuk - KSA", 
                                "Modification & Welding works.", 
                            ]
                },
    },{
        id: 22,
        featured: 4,
        title: "Etmamm Admin Bulidngs Project - Riyadh",
        description: "Project included 2 concrete buildings with full MEP services and fully furntured.",
        category: "Category 1",
        service: "Electro-mechanical",
        images:
                {
                    mainImage:"/images/projects/etmamm-admin-building-project/etmamm-admin-building-project-01.jpg",
                    slideshow:["/images/projects/etmamm-admin-building-project/etmamm-admin-building-project-01.jpg",
                    "/images/projects/etmamm-admin-building-project/etmamm-admin-building-project-02.jpg",
                    "/images/projects/etmamm-admin-building-project/etmamm-admin-building-project-03.jpg",
                    "/images/projects/etmamm-admin-building-project/etmamm-admin-building-project-04.jpg",
                    "/images/projects/etmamm-admin-building-project/etmamm-admin-building-project-05.jpg",
                    "/images/projects/etmamm-admin-building-project/etmamm-admin-building-project-06.jpg",
                    "/images/projects/etmamm-admin-building-project/etmamm-admin-building-project-07.jpg",
                    "/images/projects/etmamm-admin-building-project/etmamm-admin-building-project-08.jpg",
                                ]
                },
        details: 
                {
                        items:["client",
                                "consultant",
                                "contractor",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["Etmamm Logstics",
                                "SAK",
                                "Amana contracting",
                                "Etmamm Logstics",
                                "Riyadh -2nd industrial city - KSA", 
                                "Turn key Buildings.", 
                            ]
                },
    },{
        id: 23,
        featured: 4,
        title: "Etmamm Warehouse Project - Riyadh",
        description: "Project included 3 freezer rooms and one dry store room with total area 40,000 M2. Work scope was Mechanical, firefighting and electrical works.",
        category: "Category 1",
        service: "Electro-mechanical",
        images:
                {
                    mainImage:"/images/projects/etmamm-warehouse-project/etmamm-warehouse-project-01.jpg",
                    slideshow:["/images/projects/etmamm-warehouse-project/etmamm-warehouse-project-01.jpg",
                    "/images/projects/etmamm-warehouse-project/etmamm-warehouse-project-02.jpg",
                    "/images/projects/etmamm-warehouse-project/etmamm-warehouse-project-03.jpg",
                    "/images/projects/etmamm-warehouse-project/etmamm-warehouse-project-04.jpg",
                    "/images/projects/etmamm-warehouse-project/etmamm-warehouse-project-05.jpg",
                    "/images/projects/etmamm-warehouse-project/etmamm-warehouse-project-06.jpg",
                    "/images/projects/etmamm-warehouse-project/etmamm-warehouse-project-07.jpg",
                    "/images/projects/etmamm-warehouse-project/etmamm-warehouse-project-08.jpg",
                    "/images/projects/etmamm-warehouse-project/etmamm-warehouse-project-09.jpg",
                    "/images/projects/etmamm-warehouse-project/etmamm-warehouse-project-10.jpg",
                                ]
                },
        details: 
                {
                        items:["Client & Steel Manufacturer",
                                "consultant",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["Butler",
                                "SAK",
                                "Etmamm Logstics ",
                                "Riyadh -2nd industrial city - KSA", 
                                "MEP Works", 
                            ]
                },
    },{
        id: 24,
        featured: 4,
        title: "Khadija 3 warehouse - Jeddah",
        description: "Project included 6 freezer rooms and dry store room. Scope of work was to install the mechanical and electrical systems ( Lighting, drainage, water supply, firefighting, fire alarm). Concrete works for fences and outdoor floors.",
        category: "Category 1",
        service: "Electro-mechanical",
        images:
                {
                    mainImage:"/images/projects/khadija-3-warehouse-project/khadija-3-warehouse-project-01.jpg",
                    slideshow:["/images/projects/khadija-3-warehouse-project/khadija-3-warehouse-project-01.jpg",
                    "/images/projects/khadija-3-warehouse-project/khadija-3-warehouse-project-02.jpg",
                    "/images/projects/khadija-3-warehouse-project/khadija-3-warehouse-project-03.jpg",
                    "/images/projects/khadija-3-warehouse-project/khadija-3-warehouse-project-04.jpg",
                    "/images/projects/khadija-3-warehouse-project/khadija-3-warehouse-project-05.jpg",
                    "/images/projects/khadija-3-warehouse-project/khadija-3-warehouse-project-06.jpg",
                    "/images/projects/khadija-3-warehouse-project/khadija-3-warehouse-project-07.jpg",
                    "/images/projects/khadija-3-warehouse-project/khadija-3-warehouse-project-08.jpg",
                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "contractor",
                                "end user",
                                "location", 
                                "type of work"           
                            ],
                    values:["Ahmed A. Abed Trading Co.",
                                "Emcan Consultant",
                                "RAK",
                                "Ahmed A. Abed Trading Co.",
                                "Jeddah - KSA ", 
                                "Concrete & MEP works", 
                            ]
                },
    },{
        id: 25,
        featured: 4,
        title: "Sultan Ibrahem Palace - Riyadh",
        description: "Project included 2 buildings. Main building is the palace and our works were to establish the Electrical system and smart home system. The Mechanical works included the swimming pools pipes and pump room, drainage and water supply systems, and AC",
        category: "Category 1",
        service: "Electro-mechanical",
        images:
                {
                    mainImage:"/images/projects/sultan-ibrahem-palace-project/sultan-ibrahem-palace-project-04.jpg",
                    slideshow:["/images/projects/sultan-ibrahem-palace-project/sultan-ibrahem-palace-project-01.jpg",
                    "/images/projects/sultan-ibrahem-palace-project/sultan-ibrahem-palace-project-02.jpg",
                    "/images/projects/sultan-ibrahem-palace-project/sultan-ibrahem-palace-project-03.jpg",
                    "/images/projects/sultan-ibrahem-palace-project/sultan-ibrahem-palace-project-04.jpg",
                    "/images/projects/sultan-ibrahem-palace-project/sultan-ibrahem-palace-project-05.jpg",
                    "/images/projects/sultan-ibrahem-palace-project/sultan-ibrahem-palace-project-06.jpg",
                    "/images/projects/sultan-ibrahem-palace-project/sultan-ibrahem-palace-project-07.jpg",
                    "/images/projects/sultan-ibrahem-palace-project/sultan-ibrahem-palace-project-08.jpg",
                    "/images/projects/sultan-ibrahem-palace-project/sultan-ibrahem-palace-project-09.jpg",
                    "/images/projects/sultan-ibrahem-palace-project/sultan-ibrahem-palace-project-10.jpg",
                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "contractor",
                                "location", 
                                "type of work"           
                            ],
                    values:["Sheikh Sultan Al-Ibrahem",
                                "SAK Consultant office",
                                "SBCM",
                                "Riyadh - KSA", 
                                "MEP Works", 
                            ]
                },
    },{
        id: 26,
        featured: 4,
        title: "Caraagy Workshops - Al Khobar",
        description: "Project included 4 workshops. The works includes finishing items of Plaster, paints, ceramic, cladding, glass doors and aluminium windows. Install the MEP systems including the oil, Air, and water pipes. Electrical systems and CCTV system and AC",
        category: "Category 1",
        service: "Electro-mechanical",
        images:
                {
                    mainImage:"/images/projects/caraagy-workshops-project/caraagy-workshops-project-01.jpg",
                    slideshow:["/images/projects/caraagy-workshops-project/caraagy-workshops-project-01.jpg",
                    "/images/projects/caraagy-workshops-project/caraagy-workshops-project-02.jpg",
                    "/images/projects/caraagy-workshops-project/caraagy-workshops-project-03.jpg",
                    "/images/projects/caraagy-workshops-project/caraagy-workshops-project-04.jpg",
                    "/images/projects/caraagy-workshops-project/caraagy-workshops-project-05.jpg",
                    "/images/projects/caraagy-workshops-project/caraagy-workshops-project-06.jpg",
                    "/images/projects/caraagy-workshops-project/caraagy-workshops-project-07.jpg",
                    "/images/projects/caraagy-workshops-project/caraagy-workshops-project-08.jpg",
                    "/images/projects/caraagy-workshops-project/caraagy-workshops-project-09.jpg",
                    "/images/projects/caraagy-workshops-project/caraagy-workshops-project-10.jpg",
                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "location", 
                                "type of work"           
                            ],
                    values:["UMA (Universal motors Altawkilat)",
                                "UMA Engineering departament",
                                "Al Kohobar - KSA", 
                                "Turn Key projects", 
                            ]
                },
    },{
        id: 27,
        featured: 4,
        title: "UMA Workshop Project - Riyadh",
        description: "Project included the main workshop and showroom in riyadh. The works includes finishing items of Plaster, paints, ceramic, cladding, glass doors, steel works, and aluminium windows. Install the MEP systems including the oil, Air, and water pipes. Electrical systems and CCTV system and AC",
        category: "Category 1",
        service: "Electro-mechanical",
        images:
                {
                    mainImage:"/images/projects/uma-workshop-project/uma-workshop-project-01.jpg",
                    slideshow:["/images/projects/uma-workshop-project/uma-workshop-project-01.jpg",
                    "/images/projects/uma-workshop-project/uma-workshop-project-02.jpg",
                    "/images/projects/uma-workshop-project/uma-workshop-project-03.jpg",
                    "/images/projects/uma-workshop-project/uma-workshop-project-04.jpg",
                    "/images/projects/uma-workshop-project/uma-workshop-project-05.jpg",
                    "/images/projects/uma-workshop-project/uma-workshop-project-06.jpg",
                    "/images/projects/uma-workshop-project/uma-workshop-project-07.jpg",
                    "/images/projects/uma-workshop-project/uma-workshop-project-08.jpg",
                    "/images/projects/uma-workshop-project/uma-workshop-project-09.jpg",
                    "/images/projects/uma-workshop-project/uma-workshop-project-10.jpg",
                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "location", 
                                "type of work"           
                        ],
                values:["UMA (Universal motors Altawkilat)",
                                "UMA Engineering departament",
                                "Riyadh - KSA", 
                                "Renovation project", 
                        ]
                },
    },{
        id: 28,
        featured: 4,
        title: "Khalij AFAN Paper Project - Dammam",
        description: "Renovation and rehabilitation of the building, as it was a textile factory and was modified to be a printing press with special specifications. Concrete floors have been rehabilitated and treated with epoxy materials. The electrical systems have been renewed to suit the equipment and printing devices, and the lighting, air conditioning and drainage system have been modified to suit the new tasks.",
        category: "Category 1",
        service: "Electro-mechanical",
        images:
                {
                    mainImage:"/images/projects/afan-project/afan-project-01.jpg",
                    slideshow:["/images/projects/afan-project/afan-project-01.jpg",
                    "/images/projects/afan-project/afan-project-02.jpg",
                    "/images/projects/afan-project/afan-project-03.jpg",
                    "/images/projects/afan-project/afan-project-04.jpg",
                    "/images/projects/afan-project/afan-project-05.jpg",
                    "/images/projects/afan-project/afan-project-06.jpg",
                    "/images/projects/afan-project/afan-project-07.jpg",
                    "/images/projects/afan-project/afan-project-08.jpg",
                    "/images/projects/afan-project/afan-project-09.jpg",
                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "location", 
                                "type of work"           
                            ],
                    values:["AFAN",
                                "Al Muzel Engineering and Consulting Office",
                                "Dammam - 2nd Industrial city - KSA", 
                                "Renovation buildings", 
                            ]
                },
    },
    {
        id: 29,
        featured: 3,
        title: "NEOM Construction Villages - NEOM",
        description: "Embark on a journey of progress with Elite Signature Contracting as we bring you thrilling updates from our groundbreaking projects in collaboration with Al Fanar Projects, right in the heart of NEOM in partnership with esteemed steel manufacturers AIC Steel. Our commitment to innovation, sustainability, and shaping the future continues to evolve. Stay tuned as we share the latest snapshots of our remarkable journey. ",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/fanar/1.jpg",
                    slideshow:[
                    "/images/projects/fanar/1.jpg",
                    "/images/projects/fanar/2.jpg",
                    "/images/projects/fanar/3.jpg",
                    "/images/projects/fanar/4.jpg",
                    "/images/projects/fanar/5.jpg",
                    "/images/projects/fanar/6.jpg",
                    "/images/projects/fanar/7.jpg",
                                ]
                },
        details: 
                {
                        items:["Client",
                                "End User",
                                "Total Area",
                                "Steel Supplier",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "Al Fanar",
                                "NEOM",
                                "132,000 m2",
                                "AIC Steel",
                                "NEOM - KSA", 
                                "Erection of Steel PEB & Sandwich Panel", 
                            ]
                },
    },
    {
        id: 30,
        featured: 4,
        title: "Empty Square Project",
        description: "We are thrilled to announce a significant milestone in the Empty Quarter Project on the borders between KSA and Oman. The dream is now a tangible reality as we've successfully completed the installation of Light Gauge Steel (LGS). Our dedicated team worked tirelessly day and night to achieve this remarkable goal.",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/empty-square/1.jpeg",
                    slideshow:[
                    "/images/projects/empty-square/1.jpeg",
                    "/images/projects/empty-square/2.jpeg",
                    "/images/projects/empty-square/3.jpeg",
                    "/images/projects/empty-square/4.jpeg",
                    "/images/projects/empty-square/5.jpeg",
                    "/images/projects/empty-square/6.jpg",
                    "/images/projects/empty-square/7.png",
                                ]
                },
        details: 
                {
                        items:["Client",
                                "Total Area",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "ICON",
                                "18,000 m2",
                                "Empty Square - KSA", 
                                "Erection of LGS & All Accessories", 
                            ]
                },
    },
    {
        id: 31,
        featured: 1,
        title: "MBC Group Al Narjis Studio Complex - Riyadh",
        description: "",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/mbc-studio/1.jpg",
                    slideshow:["/images/projects/mbc-studio/1.jpg",
                    "/images/projects/mbc-studio/2.jpg",
                    "/images/projects/mbc-studio/3.jpg",
                    "/images/projects/mbc-studio/4.jpg",
                    "/images/projects/mbc-studio/5.jpg",
                    "/images/projects/mbc-studio/6.jpg",
                    "/images/projects/mbc-studio/7.jpeg",
                    "/images/projects/mbc-studio/8.jpeg",

                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "contractor",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "MBC Group",
                                "MAB Consult",
                                "EDSCO",
                                "Riyadh - KSA", 
                                "Erection of Steel PEB", 
                            ]
                },
    },
//     {
//         id: 32,
//         featured: 1,
//         title: "SANKYU Administration, Training, & Maintenance Center - Jubail",
//         description: "",        
//         category: "Category 1",
//         service: "",
//         images:
//                 {
//                     mainImage:"/images/projects/sankyu/1.jpg",
//                     slideshow:["/images/projects/sankyu/1.jpg",
//                     "/images/projects/sankyu/2.jpg",
//                     "/images/projects/sankyu/3.jpg",
//                     "/images/projects/sankyu/4.jpg",
//                     "/images/projects/sankyu/5.jpg",
//                     "/images/projects/sankyu/6.jpg",
//                     "/images/projects/sankyu/7.jpg",
//                     "/images/projects/sankyu/8.jpg",
//                     "/images/projects/sankyu/9.jpg",

//                                 ]
//                 },
//         details: 
//                 {
//                         items:["Client",
//                                 "consultant",
//                                 "contractor",
//                                 "location", 
//                                 "type of work"           
//                             ],
//                     values:[
//                                 "SANKYU KSA",
//                                 "Alhussan Engineering Consultant",
//                                 "AMANA",
//                                 "Jubail - KSA", 
//                                 "Erection of the Steel Structure", 
//                             ]
//                 },
//     },
    {
        id: 33,
        featured: 4,
        title: "Energy Investment Project",
        description: "Exciting strides are being made in our latest venture, the Energy Investment Factory Project! As we continue to work diligently, the vision of sustainable energy solutions is becoming a reality. Our team at Elite Signature Contracting is proud to contribute to this transformative project that aligns with our commitment to innovation and environmental stewardship. Stay tuned for more updates as we shape the future of sustainable energy together! ",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/sanya/1.jpg",
                    slideshow:["/images/projects/sanya/1.jpg",
                    "/images/projects/sanya/2.jpg",

                                ]
                },
        details: 
                {
                        items:["Client",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "Sanya",
                                "KSA", 
                                "Erection of Steel PEB", 
                            ]
                },
    },
    {
        id: 34,
        featured: 1,
        title: "Sportplex Project - Jeddah",
        description: "We're thrilled to announce that Elite Signature Contracting LLC has been awarded the prestigious SPORTPLEX project in Jeddah. This monumental sports infrastructure project is part of the kingdom’s entertainment vision. The project involves the supply and erection of steel structure and sandwich panels. The complex is 4 floors with a height of 30 meters. The structure system is an outstanding 56 meter clear span truss section. This cutting-edge facility will redefine the standards of sports infrastructure in the region. We're proud to be at the forefront of this ambitious project, bringing our expertise and dedication to ensure its success.",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/sportplex/1.jpg",
                    slideshow:["/images/projects/sportplex/1.jpg",
                        "/images/projects/sportplex/2.jpg",
                        "/images/projects/sportplex/3.jpg",
                        "/images/projects/sportplex/4.jpg",
                        "/images/projects/sportplex/5.jpg",

                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "project management",
                                "steel manufacturer",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "SPORTPLEX",
                                "Ahmed Fouad Banaja Consulting",
                                "DOM KSA",
                                "AIC Steel",
                                "Jeddah - KSA", 
                                "Erection of Steel PEB", 
                            ]
                },
    },
    {
        id: 35,
        featured: 4,
        title: "Masdar Warehouse and Workshop - Hafr Al Batin ",
        description: "Elite signature contracting has successfully completed this turn key project. This industrial project spanned over 15,000 SQM in Hafr Al Batin province. The project scope included the erection of steel structure, civil works, concrete works, and electromechanical works.",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/masdar/1.jpg",
                    slideshow:["/images/projects/masdar/1.jpg",
                        "/images/projects/masdar/2.jpg",
                        "/images/projects/masdar/3.jpg",
 
                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "Masdar",
                                "MAB",
                                "Hafr Al Batin - KSA", 
                                "Turnkey Project", 
                            ]
                },
    },
    {
        id: 36,
        featured: 4,
        title: "Aqua Bridge Neom Facility - Neom",
        description: "With a total area of 3000 square meters, this project involves the supply and installation of a Pre-Engineered Building (PEB) warehouse facility in the futuristic city of Neom.",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/aqua-bridge/1.jpg",
                    slideshow:["/images/projects/aqua-bridge/1.jpg",
"/images/projects/aqua-bridge/2.jpg",
"/images/projects/aqua-bridge/3.jpg",
"/images/projects/aqua-bridge/4.jpg",
                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "Aqua Bridge",
                                "AECOM",
                                "Neom - KSA", 
                                "Supply and Apply Steel PEB", 
                            ]
                },
    },
//     {
//         id: 36,
//         featured: 4,
//         title: "NOPCO Al Qassem Warehouse - Al Qassem",
//         description: "Elite signature contracting has successfully completed this turn key project. The project scope included both the concrete and steel ercetion for sandwich panels and steel structure.",
//         category: "Category 1",
//         service: "",
//         images:
//                 {
//                     mainImage:"/images/projects//",
//                     slideshow:["/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                                 ]
//                 },
//         details: 
//                 {
//                         items:["Client",
//                                 "consultant",
//                                 "location", 
//                                 "type of work"           
//                             ],
//                     values:[
//                                 "Creet",
//                                 "Jasara",
//                                 "Al Qassem - KSA", 
//                                 "Steel and Concrete Works", 
//                             ]
//                 },
//     },
    {
        id: 37,
        featured: 4,
        title: "MGBI New Extension Project - Jeddah",
        description: "The project included the successful erection of 250 tons of steel structure, completed in just two weeks for MGB. The scope included both the supply and erection, ensuring the highest standards of quality throughout the project.",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/mgb/5.jpeg",
                    slideshow:["/images/projects/mgb/1.jpeg",
                    "/images/projects/mgb/2.jpeg",
                    "/images/projects/mgb/3.jpeg",
                    "/images/projects/mgb/4.jpeg",
                    "/images/projects/mgb/5.jpeg",
                    "/images/projects/mgb/6.jpeg",
                    "/images/projects/mgb/7.jpeg",

                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "MGBI",
                                "Shahir Al Nahari",
                                "Jeddah - KSA", 
                                "Supply and Erection of Steel Structure Crane System", 
                            ]
                },
    },
//     {
//         id: 35,
//         featured: 1,
//         title: "Nojood Private Office - Jeddah",
//         description: "Elite Signature Contracting successfully completed the internal finishing works for Real Vision Company’s private office on Altahlia Street, Jeddah. The project encompassed an 850 m² plot and included eight private offices, a meeting room, two secretary offices, a reception area, and an IT room.",
//         category: "Category 1",
//         service: "",
//         images:
//                 {
//                     mainImage:"/images/projects//",
//                     slideshow:["/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                                 ]
//                 },
//         details: 
//                 {
//                         items:["Client",
//                                 "consultant",
//                                 "location", 
//                                 "type of work"           
//                             ],
//                     values:[
//                                 "",
//                                 "",
//                                 " - KSA", 
//                                 "", 
//                             ]
//                 },
//     },
//     {
//         id: 35,
//         featured: 1,
//         title: "Sary Royal Village - Jeddah",
//         description: "Elite Signature Contracting successfully completed the full construction of Sary Royal Commercial Mall over a plot area of 9,000 m² and a built up area of 5,000 m². The Mall consists of 4 floors featuring 10 shops, 12 restaurants, a 3,000 m² hypermarket, and upper and lower car park.",
//         category: "Category 1",
//         service: "",
//         images:
//                 {
//                     mainImage:"/images/projects//",
//                     slideshow:["/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                                 ]
//                 },
//         details: 
//                 {
//                         items:["Client",
//                                 "consultant",
//                                 "location", 
//                                 "type of work"           
//                             ],
//                     values:[
//                                 "",
//                                 "",
//                                 " - KSA", 
//                                 "", 
//                             ]
//                 },
//     },
//     {
//         id: 35,
//         featured: 1,
//         title: "Prince Abdullah Bin Saud Office - Jeddah",
//         description: "Elite Signature Contracting successfully completed the internal finishing works for a private office AlKhaldia district, Jeddah. The project encompassed an 250 m² plot and included a private office room with a private toilet, a meeting room, a manager’s office, two secretary offices, and a reception area.",
//         category: "Category 1",
//         service: "",
//         images:
//                 {
//                     mainImage:"/images/projects//",
//                     slideshow:["/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                                 ]
//                 },
//         details: 
//                 {
//                         items:["Client",
//                                 "consultant",
//                                 "location", 
//                                 "type of work"           
//                             ],
//                     values:[
//                                 "",
//                                 "",
//                                 " - KSA", 
//                                 "", 
//                             ]
//                 },
//     },
//     {
//         id: 35,
//         featured: 1,
//         title: "Mathbicom Restaurant - Jeddah",
//         description: "Elite Signature Contracting completed internal finishing works for a restaurant in Jeddah, owned by Almoraba Company. The project encompassed a 1200 m² area featuring a client hall, manager’s office, reception area, IT room, toilets, and kitchen area.",
//         category: "Category 1",
//         service: "",
//         images:
//                 {
//                     mainImage:"/images/projects//",
//                     slideshow:["/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                     "/images/projects//",
//                                 ]
//                 },
//         details: 
//                 {
//                         items:["Client",
//                                 "consultant",
//                                 "location", 
//                                 "type of work"           
//                             ],
//                     values:[
//                                 "",
//                                 "",
//                                 " - KSA", 
//                                 "", 
//                             ]
//                 },
//     },
    {
        id: 38,
        featured: 4,
        title: "Lexus Showrooms Project - Jeddah",
        description: "The refurbishment of Lexus showrooms in Jeddah is an iconic project involving the design, fabrication, supply, and erection of 250 tons of decorative steel structure, all within an ambitious 3-month timeline.",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/lexus/3.jpg",
                    slideshow:["/images/projects/lexus/1.jpg",
                        "/images/projects/lexus/2.jpg",
                        "/images/projects/lexus/3.jpg",
                        "/images/projects/lexus/4.jpg",

                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "Lexus",
                                "Design Grid",
                                "Jeddah - KSA", 
                                "Supply and Erection of Steel Structure", 
                            ]
                },
    },
    {
        id: 39,
        featured: 4,
        title: "El Sewedy Cables CV Line Project - Jubail",
        description: "The project scope includes the design, fabrication, supply, and erection of the CV line catwalk, with the entire project completed in a record time of just 8 weeks.",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/sewedy/1.jpg",
                    slideshow:["/images/projects/sewedy/1.jpg",
                        "/images/projects/sewedy/2.jpg",
                        "/images/projects/sewedy/3.jpg",
                        "/images/projects/sewedy/4.jpg",
                        "/images/projects/sewedy/5.jpg",
                        "/images/projects/sewedy/6.jpg",
                                ]
                },
        details: 
                {
                        items:["Client",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "El Sewedy Cables",
                                "Jubail - KSA", 
                                "Supply & Erection of Steel Structure", 
                            ]
                },
    },
    {
        id: 40,
        featured: 4,
        title: "Tabuk 2 Independant Sewage Treatment Plant - Tabuk",
        description: "The project included the successful completion of the steel structure erection for the Tabuk 2 Independent Sewage Treatment Plant, a significant project for our valued client, Saudi Water Partnership Company. Partnering with AIC Steel.",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/tabuk-2/1.jpg",
                    slideshow:["/images/projects/tabuk-2/1.jpg",
                        "/images/projects/tabuk-2/2.jpg",
                        "/images/projects/tabuk-2/3.jpg",
                        "/images/projects/tabuk-2/4.jpg",
                        "/images/projects/tabuk-2/5.jpg",
                        "/images/projects/tabuk-2/6.jpg",
                                ]
                },
        details: 
                {
                        items:["Client",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "",
                                "Tabuk - KSA", 
                                "Erection of Steel Structure", 
                            ]
                },
    },
    {
        id: 41,
        featured: 1,
        title: "SANKYU’s Administration, Training, & Maintenance Center - Jubail",
        description: "Erection of Steel Structure of SANKYU’s Maintenance and Human Resources Development Center at Jubail, Eastern Province, KSA. This facility, covering 6,000m2, is set to play a pivotal role in nurturing the training and development of human resources within the region.",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/sankyu/10.jpg",
                    slideshow:["/images/projects/sankyu/10.jpg",
                        "/images/projects/sankyu/11.jpg",
                        "/images/projects/sankyu/12.jpg",
                        "/images/projects/sankyu/13.jpg",
                        "/images/projects/sankyu/14.jpg",
                        "/images/projects/sankyu/15.jpg",
                        "/images/projects/sankyu/1.jpg",
                        "/images/projects/sankyu/2.jpg",
                        
                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "SANKYU",
                                "AMANA",
                                "Jubail - KSA", 
                                "Erection of Steel Structure", 
                            ]
                },
    },
    {
        id: 42,
        featured: 4,
        title: "Catrion Central Logistics Hub - Red Sea",
        description: "The project involves the installation of 45,000 square meters of sandwich panels supplied by the renowned Kingspan Group, showcasing our expertise and commitment to quality construction solutions.",
        category: "Category 1",
        service: "",
        images:
                {
                    mainImage:"/images/projects/catrion/1.jpg",
                    slideshow:["/images/projects/catrion/1.jpg",
                        "/images/projects/catrion/2.jpg",
                        "/images/projects/catrion/3.jpg",
                        "/images/projects/catrion/4.jpg",
                        "/images/projects/catrion/5.jpg",
                                ]
                },
        details: 
                {
                        items:["Client",
                                "consultant",
                                "location", 
                                "type of work"           
                            ],
                    values:[
                                "Catrion",
                                "AES",
                                "Red Sea - KSA", 
                                "Erection of Sandwich Panel and Steel Structure", 
                            ]
                },
    },
];

export default projects;