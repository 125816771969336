import React from 'react';
import { Link } from 'react-router-dom';
import CustomNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import newsData from '../data/newsData';
import { kebabCase } from 'lodash';
import projects from '../data/projectData';
import ProjectCard from '../components/projectCard';
import services from '../data/serviceData';
import Carousel from '../components/carousel';
import CertifSection from '../components/CertifSection';
import ClientSection from '../components/ClientSection';
import ClientSection2 from '../components/ClientSection2';
import NewsCard from '../components/newsCard';
import "../css/homepage.css"
import "../css/main.css"


function HomePage() {
  // Get the last three news articles based on their IDs
  const lastThreeNews = newsData
  .slice(-3)
  .sort((b, a) => a.id - b.id)


  // Get the three featured projects in ascending order
  const featuredProjects = projects
    .filter((project) => project.featured)
    .sort((a, b) => a.featured - b.featured)
    .slice(0, 3);
  
  // Get the ten featured services in ascending order
    const featuredServices = services
    .filter((services) => services.featured)
    .sort((a, b) => a.featured - b.featured)
    .slice(0, 10);


    // const images=["/images/homepage/elite-signature-hero-04.webp","/images/homepage/elite-signature-hero-02.webp","/images/homepage/elite-signature-hero-03.webp"]

  return (
    <div>
      <CustomNavbar />
      
      <div className="hero">

        <div className='overlay'></div>
        {/* <Carousel images={images} /> */}
        <img src="/images/homepage/elite-signature-hero-04.webp" alt=''/>
      <h1 className='animate'>ELITE SIGNATURE GROUP</h1> 
      <h2 className='animate'>PIONEERING INNOVATION IN BUILDING SOLUTIONS</h2> 

      <div className='animate esGroup'>
        <img className='esGroupLogo' src="/images/homepage/es-contracting.webp" alt=''/>
        <img className='esGroupLogo' src="/images/homepage/es-industrial.webp" alt=''/>
        {/* <img className='esGroupLogo' src="/images/homepage/es-flooring.webp" alt=''/> */}
      </div>

      </div>

      
      <div className='services-section services-section-home'>
          <h2>Providing Services Across the Middle East</h2>
          <p>At Elite Signature, we are dedicated to delivering top-notch contracting services across various industries, catering to clients with projects spanning the Middle East.</p>
          <div className='service-cards'>
          {featuredServices.map((services) => (
            <div className="service-card animate" key={services.id}>
                <p>{services.title}</p>
                <div className="overlay"></div>
                <img src={services.imgURL} alt=""></img>
            </div>
          ))}
          </div>
          <Link to="/services" className='button-link'>
          <button className='button'>All Services</button>
          </Link>
        </div>

      <div className='about-section'>
            <img src="/images/homepage/elite-signature-vision-background.webp" alt=""></img>
            <div className='about-vision-rb'>
            <div className='vision-content'>
            <h1>integrity meets vision</h1>
            </div>
            <div className='vision-content'>
            {/* <h4>our vision</h4> */}
            <p>Our vision is to be a top-tier contractor of choice, offering a comprehensive, one-stop solution for the entire industrial project lifecycle from initial advisory to seamless execution and final handover. We strive to be the preferred partner, recognized for our expertise, quality, performance, and reliability in industrial projects and steel structure solutions.</p>
            <Link to="/about" className='button-link'>
              <button className='button'>Learn More</button>
            </Link>
            </div>
            </div>
        </div>

      <div className='projects-section'>
        <h2>Featured Projects</h2>
        <p>Discover our projects where we proudly display an extensive array of services. From steel erection and supply and application to anchor bolt installation, fire paint systems, and shear stud implementation, our track record speaks volumes.</p>
          <div className='project-cards'>
            {featuredProjects.map((project) => (
              <ProjectCard 
                id={project.id}
                title={project.title}
                imgURL={project.images.mainImage}
              />
            ))}
          </div>  
          <Link to="/projects" className='button-link'>
          <button className='button'>More Projects</button>
          </Link>
      </div>


<ClientSection />
<ClientSection2 />




{/* <div className='news-section'>
      <h2>Latest News</h2>
      <p></p>
     

      <div className='news-cards'>
      {lastThreeNews.map((news) => (
        <NewsCard 
                id={news.id}
                title={news.title}
                imgURL={news.imgURL}
              />
      ))}
      </div>
      </div> */}

      <div className='latest-videos-section'>

      <h2>Latest Videos</h2>
      <p></p>

      <div className='latest-videos'>
      <iframe className='LIVid' src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7305757539592212480?compact=1" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
      <iframe className='LIVid' src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7295579834489954304?compact=1" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
      <iframe className='LIVid' src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7287710444880822272?compact=1" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
      <iframe className='LIVid' src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7112458358082686976?compact=1" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
      </div>
      
      <Link to="https://www.linkedin.com/company/elite-signature-contracting" target="_blank" rel="noopener noreferrer" className='button-link'>
          <button className='button'>LinkedIn Page</button>
          </Link>
      </div>



    <CertifSection />
      <Footer />
    </div>
  );
}

export default HomePage;
